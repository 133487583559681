// Sidebar.scss
@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.sidebar {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100vh;
  background-color: var(--primary-color);
  color: white;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  box-shadow: -4px 0 15px rgba(0,0,0,0.2);

  &.sidebar-open {
    transform: translateX(-300px);
  }

  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-md);
    background-color: rgba(255,255,255,0.1);

    h2 {
      margin: 0;
      font-size: 1.2rem;
    }

    .close-btn {
      background: none;
      border: none;
      color: white;
      cursor: pointer;
      transition: transform 0.2s ease;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .sidebar-nav {
    padding: var(--spacing-md) 0;

    &-item {
      display: flex;
      align-items: center;
      padding: var(--spacing-sm) var(--spacing-md);
      color: white;
      transition: background-color 0.3s ease;

      svg {
        margin-right: var(--spacing-sm);
      }

      &:hover {
        background-color: rgba(255,255,255,0.1);
      }
    }
  }

  .sidebar-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: var(--spacing-md);
    background-color: rgba(0,0,0,0.1);
    text-align: center;
    font-size: 0.8rem;

    .contact-info {
      margin-top: var(--spacing-sm);
      
      p {
        margin: var(--spacing-xs) 0;
      }
    }
  }

  @media (min-width: 769px) {
    display: none;
  }
}