
// Header.scss
@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.main-header {
  background-color: white;
  box-shadow: var(--shadow-light);
  position: sticky;
  top: 0;
  z-index: 100;

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-sm) var(--spacing-lg);
    max-width: 1200px;
    margin: 0 auto;
  }

  .logo img {
    height: 50px;
  }

  .main-nav {
    display: flex;
    gap: var(--spacing-md);

    a {
      color: var(--primary-color);
      font-weight: 500;
      position: relative;
      transition: color 0.3s ease;

      &:hover {
        color: var(--secondary-color);

        &::after {
          width: 100%;
        }
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 0;
        height: 2px;
        background-color: var(--secondary-color);
        transition: width 0.3s ease;
      }
    }
  }

  .mobile-menu-toggle {
    display: none;
  }

  @media (max-width: 768px) {
    .main-nav {
      display: none;

      &.active {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: white;
        padding: var(--spacing-md);
        box-shadow: var(--shadow-medium);
      }
    }

    .mobile-menu-toggle {
      display: block;
      cursor: pointer;
    }
  }
}