
// ServiceCard.scss
@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.service-card {
  @include card-style;
  padding: var(--spacing-md);
  text-align: center;
  
  &__icon {
    @include flex-center;
    color: var(--secondary-color);
    margin-bottom: var(--spacing-sm);
  }

  &__title {
    font-family: var(--font-secondary);
    color: var(--primary-color);
    margin-bottom: var(--spacing-xs);
  }

  &__description {
    color: var(--text-color);
    font-size: 0.9rem;
  }
}