// src/styles/variables.scss
:root {
    // Brand Colors
    --primary-color: #2c3e50;
    --secondary-color: #3498db;
    --accent-color: #2ecc71;
    --text-color: #333;
    --background-color: #f4f6f7;
  
    // Typography
    --font-primary: 'Roboto', sans-serif;
    --font-secondary: 'Open Sans', sans-serif;
  
    // Spacing
    --spacing-xs: 0.5rem;
    --spacing-sm: 1rem;
    --spacing-md: 1.5rem;
    --spacing-lg: 2rem;
  
    // Shadows and Elevations
    --shadow-light: 0 4px 6px rgba(0,0,0,0.1);
    --shadow-medium: 0 6px 12px rgba(0,0,0,0.15);
  }