// src/styles/mixins.scss
@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @mixin card-style {
    background: white;
    border-radius: 8px;
    box-shadow: var(--shadow-light);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  
    &:hover {
      transform: translateY(-5px);
      box-shadow: var(--shadow-medium);
    }
  }
  
  @mixin responsive-grid($columns: 3, $gap: 1rem) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: $gap;
  }