// AboutPage.scss
@import '../styles/variables.scss';
@import '../styles/mixins.scss';

.about-page {
  .about-hero {
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    color: white;
    text-align: center;
    padding: calc(var(--spacing-lg) * 2);

    h1 {
      font-size: 2.5rem;
      margin-bottom: var(--spacing-md);
    }
  }

  .about-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: calc(var(--spacing-lg) * 2);

    .mission-section {
      text-align: center;
      max-width: 800px;
      margin: 0 auto var(--spacing-lg);

      .mission-icon {
        @include flex-center;
        color: var(--secondary-color);
        margin-bottom: var(--spacing-md);
      }

      h2 {
        margin-bottom: var(--spacing-md);
        color: var(--primary-color);
      }
    }

    .values-section {
      .values-grid {
        @include responsive-grid(3, var(--spacing-lg));
      }

      .value-card {
        @include card-style;
        text-align: center;
        padding: var(--spacing-lg);

        svg {
          color: var(--secondary-color);
          margin-bottom: var(--spacing-md);
        }

        h3 {
          margin-bottom: var(--spacing-sm);
          color: var(--primary-color);
        }
      }
    }

    .team-section {
      text-align: center;
      margin-top: calc(var(--spacing-lg) * 2);

      h2 {
        margin-bottom: var(--spacing-lg);
        color: var(--primary-color);
      }

      .team-grid {
        @include responsive-grid(3, var(--spacing-lg));
      }

      .team-member {
        @include card-style;
        padding: var(--spacing-md);

        .team-photo {
          width: 200px;
          height: 200px;
          border-radius: 50%;
          object-fit: cover;
          margin-bottom: var(--spacing-md);
        }

        h3 {
          color: var(--primary-color);
          margin-bottom: var(--spacing-xs);
        }
      }
    }
  }

  @media (max-width: 768px) {
    .about-content {
      padding: var(--spacing-lg);
    }
  }
}