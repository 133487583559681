// ServicesPage.scss
@import '../styles/variables.scss';
@import '../styles/mixins.scss';

.services-page {
  .services-hero {
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    color: white;
    text-align: center;
    padding: calc(var(--spacing-lg) * 2);

    h1 {
      font-size: 2.5rem;
      margin-bottom: var(--spacing-md);
    }
  }

  .services-content {
    display: flex;
    max-width: 1400px;
    margin: 0 auto;
    padding: calc(var(--spacing-lg) * 2);

    .services-navigation {
      width: 300px;
      margin-right: var(--spacing-lg);
      border-right: 1px solid #e0e0e0;
      padding-right: var(--spacing-md);

      .category-tab {
        display: block;
        width: 100%;
        text-align: left;
        padding: var(--spacing-sm);
        background: transparent;
        border: none;
        border-left: 3px solid transparent;
        transition: all 0.3s ease;
        color: var(--text-color);
        font-weight: 500;
        cursor: pointer;

        &:hover, &.active {
          background-color: rgba(46, 134, 222, 0.1);
          border-left-color: var(--secondary-color);
          color: var(--secondary-color);
        }
      }
    }

    .services-display {
      flex: 1;

      .service-category {
        h2 {
          color: var(--primary-color);
          margin-bottom: var(--spacing-lg);
          border-bottom: 2px solid var(--secondary-color);
          padding-bottom: var(--spacing-sm);
        }

        .service-details-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: var(--spacing-md);
        }
      }

      .service-detail-card {
        background-color: white;
        border-radius: 8px;
        box-shadow: var(--shadow-light);
        padding: var(--spacing-md);
        transition: transform 0.3s ease;

        &:hover {
          transform: translateY(-5px);
          box-shadow: var(--shadow-medium);
        }

        h4 {
          color: var(--secondary-color);
          margin-bottom: var(--spacing-sm);
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .services-content {
      flex-direction: column;

      .services-navigation {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #e0e0e0;
        display: flex;
        overflow-x: auto;
        padding-bottom: var(--spacing-sm);

        .category-tab {
          flex-shrink: 0;
          border-bottom: 3px solid transparent;
          border-left: none;
        }
      }

      .services-display .service-category .service-details-grid {
        grid-template-columns: 1fr;
      }
    }
  }
}


// // ServicesPage.scss
// @import '../styles/variables.scss';
// @import '../styles/mixins.scss';

// .services-page {
//   .services-hero {
//     background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
//     color: white;
//     text-align: center;
//     padding: calc(var(--spacing-lg) * 2);

//     h1 {
//       font-size: 2.5rem;
//       margin-bottom: var(--spacing-md);
//     }
//   }

//   .services-grid {
//     @include responsive-grid(3, var(--spacing-lg));
//     padding: calc(var(--spacing-lg) * 2);
//     max-width: 1200px;
//     margin: 0 auto;
//   }
// }