// ContactPage.scss
@import '../styles/variables.scss';
@import '../styles/mixins.scss';

.contact-page {
  background-color: var(--background-color);
  min-height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-lg);

  .contact-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--spacing-lg);
    background-color: white;
    box-shadow: var(--shadow-medium);
    border-radius: 8px;
    overflow: hidden;
    max-width: 1000px;
    width: 100%;
  }

  .contact-info {
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    color: white;
    padding: calc(var(--spacing-lg) * 2);
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      margin-bottom: var(--spacing-lg);
      font-size: 1.8rem;
    }

    .contact-details {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-md);
    }

    .contact-item {
      display: flex;
      align-items: center;
      gap: var(--spacing-md);

      svg {
        color: var(--accent-color);
      }
    }
  }

  .contact-form {
    padding: calc(var(--spacing-lg) * 2);
    display: flex;
    flex-direction: column;

    h3 {
      margin-bottom: var(--spacing-lg);
      color: var(--primary-color);
      text-align: center;
    }

    .form-group {
      margin-bottom: var(--spacing-md);

      label {
        display: block;
        margin-bottom: var(--spacing-xs);
        color: var(--text-color);
      }

      input, textarea {
        width: 100%;
        padding: var(--spacing-sm);
        border: 1px solid #ddd;
        border-radius: 4px;
        transition: border-color 0.3s ease;

        &:focus {
          outline: none;
          border-color: var(--secondary-color);
        }
      }
    }

    .submit-button {
      background-color: var(--secondary-color);
      color: white;
      border: none;
      padding: var(--spacing-sm) var(--spacing-md);
      border-radius: 4px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: darken(#3498db, 10%);
      }
    }
  }

  @media (max-width: 768px) {
    .contact-container {
      grid-template-columns: 1fr;
    }
  }
}