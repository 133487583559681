
// Footer.scss
@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.main-footer {
  background-color: var(--primary-color);
  color: white;
  padding: calc(var(--spacing-lg) * 2) var(--spacing-lg);

  .footer-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-lg);
    max-width: 1200px;
    margin: 0 auto;
  }

  .footer-column {
    display: flex;
    flex-direction: column;

    h4 {
      margin-bottom: var(--spacing-md);
      font-weight: 600;
      color: var(--accent-color);
    }

    a {
      color: white;
      margin-bottom: var(--spacing-xs);
      transition: color 0.3s ease;

      &:hover {
        color: var(--secondary-color);
      }
    }

    .contact-info {
      @include flex-center;
      justify-content: flex-start;
      gap: var(--spacing-sm);
      margin-bottom: var(--spacing-xs);

      svg {
        color: var(--secondary-color);
      }
    }

    .social-icons {
      @include flex-center;
      justify-content: flex-start;
      gap: var(--spacing-md);

      a {
        color: white;
        transition: color 0.3s ease;

        &:hover {
          color: var(--secondary-color);
        }
      }
    }
  }

  .copyright {
    text-align: center;
    margin-top: var(--spacing-lg);
    padding-top: var(--spacing-md);
    border-top: 1px solid rgba(255,255,255,0.1);
    font-size: 0.9rem;
  }

  @media (max-width: 768px) {
    .footer-container {
      grid-template-columns: 1fr;
    }
  }
}