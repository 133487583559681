// src/pages/HomePage.scss
@import '../styles/variables.scss';
@import '../styles/mixins.scss';

.home-page {
  // Hero Section
  .hero {
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    color: white;
    text-align: center;
    padding: calc(var(--spacing-lg) * 3);

    &__content {
      max-width: 800px;
      margin: 0 auto;

      h1 {
        font-size: 2.5rem;
        margin-bottom: var(--spacing-sm);
        font-weight: bold;
      }

      h2 {
        font-size: 1.8rem;
        margin-bottom: var(--spacing-md);
      }

      .hero__tagline {
        font-style: italic;
        margin-bottom: var(--spacing-lg);
      }
    }

    &__cta-buttons {
      display: flex;
      justify-content: center;
      gap: var(--spacing-md);
    }
  }

  // About Section
  .about-section {
    padding: calc(var(--spacing-lg) * 2);
    background-color: var(--background-color);

    .about-content {
      max-width: 1000px;
      margin: 0 auto;
      text-align: center;

      h2 {
        margin-bottom: var(--spacing-lg);
        color: var(--primary-color);
      }
    }

    .about-highlights {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: var(--spacing-lg);
      margin-top: var(--spacing-lg);

      .highlight {
        background: white;
        padding: var(--spacing-md);
        border-radius: 8px;
        box-shadow: var(--shadow-light);

        h3 {
          color: var(--secondary-color);
          margin-bottom: var(--spacing-sm);
        }
      }
    }
  }

  // Services Preview
  .services-preview {
    padding: calc(var(--spacing-lg) * 2);
    text-align: center;

    h2 {
      margin-bottom: var(--spacing-lg);
      color: var(--primary-color);
    }

    .services-grid {
      @include responsive-grid(4, var(--spacing-md));
    }
  }

  // Industries Served
  .industries-served {
    background-color: var(--background-color);
    padding: calc(var(--spacing-lg) * 2);
    text-align: center;

    h2 {
      margin-bottom: var(--spacing-lg);
      color: var(--primary-color);
    }

    .industries-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: var(--spacing-md);

      .industry-card {
        background: white;
        padding: var(--spacing-md);
        border-radius: 8px;
        box-shadow: var(--shadow-light);
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }

  // Testimonials
  .testimonials {
    padding: calc(var(--spacing-lg) * 2);
    text-align: center;

    h2 {
      margin-bottom: var(--spacing-lg);
      color: var(--primary-color);
    }

    .testimonials-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: var(--spacing-md);

      .testimonial-card {
        background: white;
        padding: var(--spacing-md);
        border-radius: 8px;
        box-shadow: var(--shadow-light);

        .testimonial-quote {
          font-style: italic;
          margin-bottom: var(--spacing-sm);
        }

        .testimonial-author {
          display: flex;
          flex-direction: column;
          
          .name {
            font-weight: bold;
            color: var(--primary-color);
          }

          .industry {
            color: var(--text-color);
            font-size: 0.9rem;
          }
        }
      }
    }
  }

  // Contact CTA
  .contact-cta {
    background: linear-gradient(135deg, var(--secondary-color), var(--primary-color));
    color: white;
    padding: calc(var(--spacing-lg) * 2);
    text-align: center;

    .contact-content {
      max-width: 800px;
      margin: 0 auto;

      h2 {
        margin-bottom: var(--spacing-md);
      }

      .contact-details {
        margin: var(--spacing-lg) 0;
        
        p {
          margin: var(--spacing-sm) 0;
        }
      }
    }
  }

  // Utility Classes
  .cta-button {
    @include flex-center;
    padding: var(--spacing-sm) var(--spacing-md);
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;

    &.primary {
      background-color: var(--accent-color);
      color: white;
    }

    &.secondary {
      background-color: white;
      color: var(--primary-color);
    }

    &:hover {
      transform: scale(1.05);
    }
  }
}


// // HomePage.scss
// @import '../styles/variables.scss';
// @import '../styles/mixins.scss';

// .home-page {
//   .hero {
//     background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
//     color: white;
//     text-align: center;
//     padding: calc(var(--spacing-lg) * 3);

//     &__content {
//       max-width: 800px;
//       margin: 0 auto;

//       h1 {
//         font-size: 2.5rem;
//         margin-bottom: var(--spacing-sm);
//       }

//       h2 {
//         font-size: 1.8rem;
//         margin-bottom: var(--spacing-md);
//       }
//     }

//     .cta-button {
//       @include flex-center;
//       background-color: var(--accent-color);
//       color: white;
//       border: none;
//       padding: var(--spacing-sm) var(--spacing-md);
//       border-radius: 4px;
//       transition: transform 0.3s ease;

//       &:hover {
//         transform: scale(1.05);
//       }
//     }
//   }

//   .services-preview {
//     padding: calc(var(--spacing-lg) * 2);
//     text-align: center;

//     h2 {
//       margin-bottom: var(--spacing-lg);
//     }

//     .services-grid {
//       @include responsive-grid(4, var(--spacing-md));
//     }
//   }
// }